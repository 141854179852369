.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-table {
  margin-bottom: 20px;
}

.input-table td {
  padding: 5px;
}

.input-table input {
  width: 100px;
}

.simulate-button, .animation-button {
  display: inline-block;
  background-color: #007BFF;
  color: #ffffff;
  padding: 6px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin: 10px;
  width: auto;
}

.simulate-button:hover, .animation-button:hover {
  background-color: #0056b3;
}

#simulation-player-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.player-disclaimer {
  font-size: 10px;
}

#simulation-statistics p {
  text-align: center;
  max-width: 100vw;
  word-wrap: break-word;
}

@media only screen and (max-width: 428px) {
  .passenger-tooltip {
    position: fixed !important; /* Fixed positioning to place it relative to the viewport */
    top: 50% !important; /* Center vertically */
    left: 50% !important; /* Center horizontally */
    transform: translate(-50%, -50%) !important; /* Adjust for exact centering */
    z-index: 1000 !important; /* High z-index to ensure it appears above other elements */
    max-width: calc(100% - 20px) !important; /* Provide some margin */
    white-space: normal !important; /* Allow wrapping */
    height: 100px;
    width: 250px;
    font-size: 16px;
  }
}

.passenger-container {
  position: relative;
  cursor: pointer;
  display: inline-block; /* Allow the container to fit its content */
}

.passenger-tooltip {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip */
  opacity: 0;
  min-width: 300px; /* Minimum width, you can adjust this */
  max-width: calc(100vw - 20px); /* Maximum width, considering some margin */
  white-space: normal; /* Allow text to wrap to the next line */
  word-wrap: break-word; /* Break words if necessary to prevent overflow */
  transition: opacity 0.3s;
  font-size: 12px;
}

.passenger-container:hover .passenger-tooltip {
  visibility: visible;
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75); /* Darker overlay for better focus on modal */
}

.ReactModalPortal .ReactModal__Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px; /* Softer border radius */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25); /* Subtle shadow */
  max-width: 400px; /* Reduced width for a more compact look */
  width: auto; /* Auto width to only take up necessary space */
  position: relative;
  overflow: hidden; /* Ensures no content spills out */
}

.ReactModal__Content.ReactModal__Content--after-open {
  inset: unset !important;
}

.ReactModalPortal h2 {
  color: #333;
  font-size: 20px; /* Smaller font size for headings */
  font-weight: 600; /* Less bold for a lighter appearance */
  margin-bottom: 10px; /* Reduced margin */
}

.ReactModalPortal p {
  color: #444; /* Darker color for better readability */
  line-height: 1.4; /* Adjusted line height for better text flow */
  margin-bottom: 10px; /* Add margin to separate paragraphs */
  font-size: 14px; /* Smaller font size for content */
}

.ReactModalPortal a {
  color: #007bff;
  text-decoration: underline; /* Underline to indicate interactivity */
  padding: 5px;
  display: inline-block;
  margin-top: 5px; /* Reduced margin */
  margin-bottom: 10px; /* Reduced margin */
}

.ReactModalPortal .social-share-buttons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Reduced gap */
  margin-top: 15px; /* Reduced margin */
  margin-bottom: 20px; /* Reduced margin */
}

.ReactModalPortal .social-share-buttons img {
  width: 40px; /* Smaller icons */
  height: 40px; /* Smaller icons */
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.ReactModalPortal .social-share-buttons img:hover {
  transform: scale(1.1); /* Slight grow effect on hover */
}

.ReactModalPortal button {
  background-color: #007BFF;
  color: #ffffff;
  padding: 8px 20px; /* Adjusted padding for better button sizing */
  text-align: center;
  text-decoration: none;
  font-size: 16px; /* Slightly smaller font size */
  font-weight: 500; /* Lighter font weight */
  border-radius: 5px; /* Softer border radius */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin: auto; /* Reduced margin */
  display: block;
  min-width: 100px;
}

.ReactModalPortal button:hover {
  background-color: #0056b3;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
}